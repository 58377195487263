* {
  margin:0;
  padding:0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --bg-color: rgba(0, 35, 192, 10);
  --bg-variant-color: darkblue;
  --txt-color: #fff;
  --txt-variant-color:blue;
  --container-width-big: 75%;
  --container-width-med: 85%;
  --container-width-sm: 90%;

}


#navbar {
  background-color: var(--bg-color);
  text-align: center;
}

#navbar li {
  display: inline;
}

li a {
  display: inline-block;
  padding: 10px;
  text-decoration: none;
  font-size: x-large;
  color: #fff;
}

li a:hover {
  background-color: var(--bg-variant-color);
}

li:last-child {
  border-right: none;
}

.active {
  background-color: #adadad;
}

#hellointro {
  color: #fff;
  height: auto;
  width: auto;
  display: flex;
}

#hellotitle {
  color: #fff;
  height: auto;
  width: auto;
  display: flex;
}

#home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 750px;
  background-image: url("./img/website.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Lucida Console", "Courier New", monospace;
}

#frontbuttons {
  display:grid;
  grid-template-columns: 1fr 1fr;
}

#frontbuttons button{
  display: flex;
  background-color: var(--bg-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 1rem;
  margin: 1rem;
  border: 1px solid #000000;
}

#frontbuttons button:hover{
  background-color: var(--bg-variant-color);
}


p {
  letter-spacing: 1px;
  line-height: 25px;
  font-size: 20px;
  padding: 10px 0px;
}

.large-card {
  height: 350px;
  width: 250px;
  color: #fff;
  background: var(--bg-color);
  border-radius: 4px;
  text-align: center;
  border: 1px solid #000000;
  position: relative;
  margin: 0 1rem;
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
}

.small-card {
  height: 250px;
  width: 200px;
  color: #fff;
  background: var(--bg-color);
  border-radius: 4px;
  text-align: center;
  border: 1px solid #000000;
  position: relative;
  margin: 0 1rem;
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
}

.small-card:hover {
  background-color: var(--bg-variant-color);
  cursor: pointer;
}

.large-card:hover {
  background-color: var(--bg-variant-color);
  cursor: pointer;
}

.small-card li {
  font-size: small;
  display: block;
  text-align: center;
  margin: 25px 0px;
  color: #fff;
}

.large-card li {
  font-size: small;
  display: block;
  text-align: center;
  margin: 15px 0px;
  color: #fff;
}

.bronzetitle {
  font-size: large;
}

.silvertitle {
  font-size: large;
}

.goldtitle {
  font-size: large;
}

.setuptitle {
  font-size: large;
}

.card-container-one {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.card-container-two {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

#mypicture img {
  max-width: 30%;
  height: auto;
  border: 1px solid #000000;
  border-radius: 5%;
  margin: 5% 0%;
}

#myabout {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 80vh;
  background-color: var(--bg-color);
}

#aboutbg {
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#myaboutme {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 7% 0 0 0;
}

#aboutparagraph {
  text-align: left;
  margin: 0 7rem ;
}

.progicons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  font-size: 5rem;
}

.progicons :hover {
  font-size: 8rem;
  margin-top: -15%;
  margin-bottom: -15%;
}

#skills {
  text-align: center;
}

.cardicons {
  font-size: large;
}

#services {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 900px;
  margin: 2rem 0;
}

#servicesbg {
  color: var(--bg-color);
  display: flex;
  justify-content: center;
  flex-direction: column;
  
}

#servicehead {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

.servicetitle {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

#supporttext {
  display: flex;
  color: var(--bg-color);
  align-items: center;
  justify-content: center;
}

#portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: var(--bg-color);
  margin: 2rem 0;
}

#portfoliobg {
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#port {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15% 0 0 0;
}

#porttitle {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

#hkmt {
  height: 350px;
  width: 250px;
}

#hkmt:hover {
  opacity: 0.75;
}

#ContactForm {
  display: flex;
  border-radius: 5px;
  border: 2px solid #000000;
  color: #fff;
  flex-direction: column;
  background-color: var(--bg-color);
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
  padding: 15%;
  margin: 1rem 0;
}

#contactme {
  display: grid;
  grid-template-columns: 50%;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

#contactbg {
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#contactheader {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

input[type=text] {
  margin-top: 12px;
  margin-bottom: 16px;
}

input[type=email] {
  margin-top: 12px;
  margin-bottom: 16px;
  padding: 15px 25px;
  color: var(--txt-variant-color);
  border: 1px solid #222222;
  border-radius: 4px;
  width: 100%;
}

input[type=text],
select,
textarea {
  padding: 15px 25px;
  color: var(--txt-variant-color);
  border: 1px solid #222222;
  border-radius: 4px;
  width: 100%;
}

label {
  display: inline-block;
  padding: 11% 11% 11% 0%;
}

input[type=submit] {
  background-color: #fff;
  color: var(--txt-variant-color);
  padding: 2.5% 5%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 22px;
  margin-bottom: 16px;
  border: 1px solid #000000;
}

input[type=submit]:hover {
  background-color: #adadad;
}

.foot {
  background-color: var(--bg-color);
  color: #fff;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 960px) {
  li a { 
    font-size: small;
  }


  #skillsinclude {
    font-size: small;
  }

  .progicons {
    font-size: 2.5rem;
  }

  #mypicture img {
    max-width: 60%;
  }

  #aboutparagraph {
    font-size: small;
    margin: 0 1.5rem;
  }

  #myaboutme {
    margin: 30% 0 0 0;
  }

  .large-card {
    margin: 1rem 0;
  }

  .small-card {
    margin: 1rem 0;
  }

  .card-container-one {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-self: center;
  }

  .card-container-two {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-self: center;
  }

  #services {
    height:325vh;
  }
  #customtext {
    font-size: small;
  }

  #supporttext {
    font-size: small;
  }

  #portfolio {
    height: 175vh;
  }

  #port {
    margin: 40% 0 0 0;
  }

  #ContactForm {
    padding: 0 10%;
  }

}